import React, { useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  OriginatorIdContext,
  AccessTokenContext,
} from "../context/contextProvider";
import { useGetOriginatorsQuery } from "./services";
import AuthLayout from "../shared/AuthLayout";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import DashboardCard from "../dashboard/DashboardCard";
import NoAccessPage from "../shared/NoAccessPage";

const Originators = () => {
  const { setOriginatorId, setOriginatorName } =
    useContext(OriginatorIdContext)!;
  const navigate = useNavigate();

  const accessToken = useContext(AccessTokenContext).accessToken;

  const { data, error } = useGetOriginatorsQuery(
    { accessToken },
    { skip: !accessToken }
  );

  const handleCardClick = useCallback(
    (originatorId: string, originatorName: string) => {
      setOriginatorId(originatorId);
      setOriginatorName(originatorName);
      navigate("/dashboard");
    },
    [setOriginatorId, setOriginatorName, navigate]
  );

  useEffect(() => {
    if (data?.originators.length === 1) {
      handleCardClick(
        data.originators[0].originator_id,
        data.originators[0].name
      );
    }
  }, [data, handleCardClick]);

  const hoverBoxShadow = useColorModeValue("lg", "dark-lg");

  if (error || !data || data.originators.length === 0) {
    return <NoAccessPage />;
  }

  return (
    <AuthLayout>
      <DashboardCard title="Choose Originator" w="full">
        <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={6}>
          {data.originators.map((originator) => (
            <GridItem key={originator.originator_id}>
              <Box
                borderWidth="3px"
                borderRadius="lg"
                overflow="hidden"
                p={4}
                cursor="pointer"
                transition="transform 0.2s, box-shadow 0.4s"
                _hover={{
                  boxShadow: hoverBoxShadow,
                }}
                onClick={() =>
                  handleCardClick(originator.originator_id, originator.name)
                }
              >
                <VStack>
                  <Heading size="md">{originator.name}</Heading>
                  {/* Add more details or styling as needed */}
                </VStack>
              </Box>
            </GridItem>
          ))}
        </Grid>
      </DashboardCard>
    </AuthLayout>
  );
};

export default Originators;
