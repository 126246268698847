import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import AuthLayout from "./AuthLayout";

const NoAccessPage = () => {
  return (
    <AuthLayout>
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, teal.400, teal.600)"
          backgroundClip="text"
        >
          403
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Access Denied!
        </Text>
        <Text color={"gray.500"} mb={6}>
          You don't have access to this page. Contact your administrator.
        </Text>
      </Box>
    </AuthLayout>
  );
};

export default NoAccessPage;
