import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const AuthCallback = () => {
  const navigate = useNavigate();
  const { handleRedirectCallback } = useAuth0();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const { appState } = await handleRedirectCallback();
        const redirectPath = appState?.target || "/originators";
        navigate(redirectPath, { replace: true });
      } catch (error) {
        console.error("Error during authentication callback:", error);
        navigate("/originators", { replace: true });
      }
    };

    handleCallback();
  }, [handleRedirectCallback, navigate]);

  return <div>Loading...</div>;
};

export default AuthCallback;
